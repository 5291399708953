<template>
  <div class="encrypt-container">
    <h1>COCOS加密</h1>
    <div class="form-container">
      <div class="input-group">
        <a-input v-model:value="packageName" placeholder="请输入包名" :disabled="isDisabled" class="package-input" />
      </div>

      <div class="upload-group">
        <a-upload
          v-model:file-list="fileList"
          :max-count="1"
          :progress="progress"
          listType="text"
          name="file"
          :action="uploadLink"
          :data="{ businessType: 'cocos_encrypt' }"
          @change="fileUpload"
        >
          <a-button type="primary" ghost class="minibtn">
            <upload-outlined></upload-outlined>
            选取文件
          </a-button>
        </a-upload>
      </div>
      <div class="button-group">
        <a-button type="primary" @click="handleUpload">上传</a-button>
      </div>

      <!-- 修改结果展示区域 -->
      <div v-if="isPolling || encryptResult" class="result-container">
        <div class="result-title">{{ isPolling ? '处理状态：' : '加密结果：' }}</div>
        <div class="result-content" :class="{ polling: isPolling }">
          {{ isPolling ? '正在查询结果中...' : encryptResult }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { message, type UploadProps, type UploadChangeParam } from 'ant-design-vue';
import { saveTask, getTaskStatus } from '@/api/cocos';

const route = useRoute();
const packageName = ref('');
const isDisabled = ref(false);
const fileList = ref<any[]>([]);
const uploadLink = `${import.meta.env.VITE_BASE_API || import.meta.env.VITE_BASE_API_PROXY}/distributionServ/upload/file`;

// 处理路由参数
onMounted(() => {
  const pkg = route.query.pkg as string;
  if (pkg) {
    packageName.value = pkg;
    isDisabled.value = true;
  }
});

// 新增状态
const encryptResult = ref('');
let pollingTimer: ReturnType<typeof setInterval> | null = null;

// 添加轮询状态
const isPolling = ref(false);

// 添加超时时间常量（5分钟 = 300000毫秒）
const POLLING_TIMEOUT = 300000;
const startTime = ref<number>(0);

const startPolling = (taskId: string) => {
  if (pollingTimer) return;
  isPolling.value = true;
  startTime.value = Date.now(); // 记录开始时间

  const poll = async () => {
    try {
      // 检查是否超时
      if (Date.now() - startTime.value > POLLING_TIMEOUT) {
        message.error('查询超时，请稍后重试');
        encryptResult.value = '轮询超时失败';
        stopPolling();
        return;
      }

      const res = await getTaskStatus({ taskId });
      if (res.code === '200') {
        const cosPath = res.data?.extra?.cocosEncryptCosPath;
        if (cosPath) {
          encryptResult.value = cosPath;
          stopPolling();
          message.success('加密完成');
        }
      } else {
        message.error('查询状态失败');
        stopPolling();
      }
    } catch (error) {
      message.error('查询状态失败');
      stopPolling();
    }
  };

  pollingTimer = setInterval(poll, 2000);
};

const stopPolling = () => {
  if (pollingTimer) {
    clearInterval(pollingTimer);
    pollingTimer = null;
    isPolling.value = false; // 设置轮询状态为 false
  }
};

const handleUpload = async () => {
  if (!packageName.value) {
    message.error('请输入包名');
    return;
  }

  if (fileList.value.length === 0) {
    message.error('请选择要上传的文件');
    return;
  }

  const file = fileList.value[0];
  if (file.status !== 'done') {
    message.error('请等待文件上传完成');
    return;
  }

  try {
    const res = await saveTask({
      params: {
        pkg: packageName.value,
        cosZipPath: file.response?.data?.fileUrl,
        businessId: route.query.businessId || null,
      },
    });
    if (res.code === '200') {
      message.success('上传成功');
      const taskId = res.data.taskId;
      // 开始轮询任务状态
      startPolling(taskId);
    } else {
      message.error('上传失败');
    }
  } catch (error) {
    message.error('上传失败');
  }
};

const alertShown = ref(false);
const userFileList = ref<UploadProps['fileList']>([]);
const uploadUrl = ref('');

const fileUpload = (info: UploadChangeParam) => {
  if (info.fileList.length === 0) {
    // 文件已被删除，清空相关字段
    uploadUrl.value = '';
    alertShown.value = false;
    return;
  }
  if (info.file.status === 'done') {
    alertShown.value = false;
    const res = info.file.response;
    if (res.code === '200') {
      message.success('文件上传成功');
      uploadUrl.value = res.data.fileUrl;
      userFileList.value = info.fileList.map((file) => ({
        ...file,
        status: 'done',
        url: res.data.fileUrl,
      }));
    } else {
      message.error(`上传失败：${res.message}`);
      userFileList.value = [];
    }
  } else if (info.file.status === 'error') {
    message.error('文件上传失败');
    userFileList.value = [];
  }
};

const progress: UploadProps['progress'] = {
  strokeColor: {
    '0%': '#1371FF',
    '100%': '#1371FF',
  },
  strokeWidth: 3,
  format: (percent) => (percent !== undefined ? `${parseFloat(percent.toFixed(2))}%` : ''),
  class: 'test',
};

// 组件卸载时清理轮询
onUnmounted(() => {
  stopPolling();
});
</script>

<style scoped lang="less">
.encrypt-container {
  padding: 24px;
  background: white;
  .form-container {
    max-width: 600px;
    margin-top: 24px;
  }

  .input-group {
    margin-bottom: 24px;

    .package-input {
      width: 300px;
    }
  }

  .upload-group {
    margin-bottom: 24px;

    .upload-tip {
      color: #999;
      font-size: 14px;
      margin-top: 8px;
    }

    .upload-item {
      margin-top: 8px;
    }
  }

  .button-group {
    display: flex;
    justify-content: flex-start;
  }

  .result-container {
    margin-top: 24px;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 4px;

    .result-title {
      font-weight: bold;
      margin-bottom: 8px;
    }

    .result-content {
      word-break: break-all;
      color: #1371ff;
      &.polling {
        color: #faad14; // 轮询状态使用警告色
      }
    }
  }
}
</style>
