import request from './request';

const nodeApi = import.meta.env.VITE_NODE_API;

export const getOriginData = (data: object) => request('/distributionServ/pub/download', 'GET', data);
export const toggleMark = (data: object) => request('/distributionServ/pub/download/mark', 'POST_JSON', data);

export const getDeviceList = (data: object) => request(`${nodeApi}/api/devices`, 'GET', data);
export const getVersionList = (data: object) => request(`${nodeApi}/api/versions`, 'GET', data);
export const getSelfTestList = (data: object) => request(`${nodeApi}/api/self-check-items`, 'GET', data);
