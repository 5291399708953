import { createRouter, createWebHistory } from 'vue-router';
import NProgress from '@/utils/progress';
import BuildView from '../views/build/index.vue';
import CompareView from '../views/compare/index.vue';
import EncryptView from '../views/cocos/encrypt/index.vue';

const routes = [
  {
    path: '/',
    redirect: '/download',
  },
  {
    path: '/download',
    name: 'download1',
    component: BuildView,
  },
  {
    path: '/download/:id',
    name: 'download',
    component: BuildView,
  },
  {
    path: '/compare/:id',
    name: 'compare',
    component: CompareView,
  },
  {
    path: '/cocos/encrypt',
    name: 'cocosEncrypt',
    component: EncryptView,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes: import.meta.env.VITE_SINGLE_SiGN ? [] : routes,
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
