function checkFn(detail: Record<string, any>, funcKey: string | string[]): boolean {
  if (!detail?.funcList?.length) {
    return false;
  }

  let keys: string[] = [];
  if (Array.isArray(funcKey)) {
    keys = funcKey;
  } else {
    keys = [funcKey];
  }

  const func = detail.funcList.find((x: Record<string, any>) => {
    return keys.includes(x.key) && x.value == '1';
  });
  return !!func;
}

export const checkList = [
  {
    group: 'Banner广告',
    items: [
      { name: '展示', eventName: 'show_banner', eventParams: [] },
      { name: '关闭', eventName: 'hide_banner', eventParams: [] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, '广告');
    },
  },
  {
    group: '插屏广告',
    items: [
      { name: '点击', eventName: 'show_interstitial1', eventParams: ['game', 'interName', 'type'] },
      { name: '展示', eventName: 'interstitial_show', eventParams: [] },
      { name: '关闭', eventName: 'interstitial_close', eventParams: [] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, '广告');
    },
  },
  {
    group: '视频广告',
    items: [
      { name: '视频准备', eventName: 'is_video_ready', eventParams: [] },
      { name: '播放视频', eventName: 'show_video', eventParams: [] },
      { name: 'trackvideo', eventName: 'trackvideo', eventParams: ['type'] },
      { name: '视频播放结果', eventName: 'afterVideo', eventParams: ['success', 'videoFlag'] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, '广告');
    },
  },
  {
    group: '新事件统计',
    items: [
      { name: '统计被调用', eventName: 'event', eventParams: ['eventId', 'jsonProperties'] },
      { name: '一次一次性', eventName: 'event_once', eventParams: ['eventId', 'jsonProperties'] },
    ],
  },
  {
    group: '购买',
    items: [
      { name: '调用', eventName: 'new_order', eventParams: ['productID'] },
      { name: '购买成功', eventName: 'plat_success', eventParams: ['orderId'] },
      { name: '购买失败', eventName: 'plat_failed', eventParams: ['orderId', 'errorMsg'] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, '支付');
    },
  },
  {
    group: '发货',
    items: [{ name: '调用', eventName: 'buy_success', eventParams: ['orderId'] }],
    check(data: Record<string, any>) {
      return checkFn(data, '支付');
    },
  },
  {
    group: '登录',
    items: [
      { name: '登录调用', eventName: 'server_login', eventParams: [] },
      { name: '登录成功', eventName: 'login_callback', eventParams: ['code', 'userInfo'] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, ['谷歌登入', 'FaceBook登录', 'LINE登录', '游客登入', '微信登录', 'QQ登录']);
    },
  },
  {
    group: '注销',
    items: [
      { name: '注销调用', eventName: 'login_off', eventParams: [] },
      { name: '注销成功', eventName: 'login_off_callback', eventParams: [] },
    ],
    check(data: Record<string, any>) {
      return checkFn(data, ['谷歌登入', 'FaceBook登录', 'LINE登录', '游客登入', '微信登录', 'QQ登录']);
    },
  },
  {
    group: 'GDPR',
    items: [
      { name: '欧盟地区检查', eventName: 'location_in_eea', eventParams: [] },
      { name: '展示GDPR', eventName: 'show_gdpr', eventParams: [] },
    ],

    check(data: Record<string, any>) {
      return checkFn(data, 'GDPR');
    },
  },
  {
    group: '获取商品信息',
    items: [
      { name: '调用', eventName: 'get_product_info', eventParams: [] },
      { name: '返回信息', eventName: 'product_info_callback', eventParams: ['productID', 'productInfo'] },
    ],

    check(data: Record<string, any>) {
      return checkFn(data, 'GDPR') && checkFn(data, '支付');
    },
  },
  {
    group: '协议与隐私',
    items: [
      { name: '协议弹窗', eventName: 'goto_privacy_policy', eventParams: [] },
      { name: '隐私弹窗', eventName: 'goto_terms_service', eventParams: [] },
    ],
  },
  {
    group: '（硬核市场）更多设置',
    items: [
      { name: '是否显示更多设置', eventName: 'is_show_more_setting', eventParams: [] },
      { name: '进入更多设置调用', eventName: 'start_setting', eventParams: [] },
    ],
    check(data: Record<string, any>) {
      const xChannel = (data.chnl || '').toLowerCase();
      return (
        xChannel.includes('oppo') ||
        xChannel.includes('vivo') ||
        xChannel.includes('huawei') ||
        xChannel.includes('华为') ||
        xChannel.includes('xiaomi') ||
        xChannel.includes('小米')
      );
    },
  },
  {
    group: '（OPPO）超休闲',
    items: [{ name: '超休闲游戏点击调用', eventName: 'jump_leisure', eventParams: [] }],
    check(data: Record<string, any>) {
      const xChannel = (data.chnl || '').toLowerCase();
      return xChannel.includes('oppo');
    },
  },
];

// 转成对象
export function toCheckObj() {
  const checkObj: Record<string, number> = {}; // 保存自检项目状态
  checkList.forEach((item) => {
    item.items.forEach((x) => {
      checkObj[x.eventName] = 0; // 0 未检测  1：warning  2：通过
    });
  });

  return checkObj;
}

// 转成数组
export function toCheckParams() {
  const checkObj: Record<string, string[]> = {}; // 保存自检项目状态
  checkList.forEach((item) => {
    item.items.forEach((x) => {
      if (x.eventParams.length) {
        checkObj[x.eventName] = x.eventParams;
      }
    });
  });

  return checkObj;
}
