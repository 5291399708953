<template>
  <div class="self-test-box" style="margin-top: 16px" v-if="store.data?.reqSource == 'intranet'">
    <div
      :style="
        isMobile
          ? { display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: '16px', gap: '10px' }
          : { display: 'flex', alignItems: 'center', marginBottom: '16px' }
      "
    >
      <div class="inner-title" style="margin-right: 20px; margin-bottom: 0">
        <SvgIcon iconClass="tab"></SvgIcon>
        自检项
      </div>
      <div style="display: flex; align-items: center; font-size: 14px" :class="['selectbox', isMobile ? 'mobilebox' : '']">
        <div style="display: flex; align-items: center; margin-right: 20px; font-size: 14px" class="boxitem">
          <span style="flex-shrink: 0" class="boxlabel">设备</span>
          <a-select
            style="margin-left: 10px; flex-grow: 1"
            v-model:value="selectedDevice"
            @change="fetchSelfTestList"
            showSearch
            allow-clear
            :options="deviceList"
            placeholder="请选择设备"
          ></a-select>
        </div>
        <div style="display: flex; align-items: center; font-size: 14px" class="boxitem">
          <span style="flex-shrink: 0" class="boxlabel">版本</span>
          <a-select
            style="margin-left: 10px; flex-grow: 1"
            v-model:value="selectedVersion"
            @change="fetchSelfTestList"
            :options="versionList"
            placeholder="请选择版本"
          ></a-select>
        </div>
      </div>
    </div>

    <a-table
      :loading="loading"
      :pagination="false"
      :columns="columns"
      :data-source="dataSource"
      size="small"
      bordered
      :scroll="isMobile ? {} : { x: 'calc(700px + 50%)', y: 240 }"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="!isMobile || (isMobile && column.dataIndex === 'status')">
          <div v-if="record[column.dataIndex as string] === 2" style="display: flex; align-items: center; justify-content: center">
            <CheckCircleOutlined style="font-size: 20px; font-weight: bold; color: green" />
          </div>
          <div v-else-if="record[column.dataIndex as string] === 1" style="display: flex; align-items: center; justify-content: center">
            <a-popover>
              <template #content>
                <div>
                  {{ checkErrorMsg[isMobile ? record['eventName'] : (column.dataIndex as string)] }}
                </div>
              </template>
              <WarningOutlined style="font-size: 20px; font-weight: bold; color: yellowgreen" />
            </a-popover>
          </div>
          <div v-else style="display: flex; align-items: center; justify-content: center">
            <CloseCircleOutlined style="font-size: 20px; font-weight: bold; color: red" />
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script setup lang="ts">
import { getDeviceList, getVersionList, getSelfTestList } from '@/api/download';
import { has, difference, uniq } from 'lodash';
import { checkList, toCheckParams, toCheckObj } from './selfTestData';
import { CheckCircleOutlined, WarningOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { buildResult } from '../hooks/useResult';

import { useOriginDataStore } from '@/stores/originDataStore';

const deviceList = ref<{ label: string; value: string }[]>([]);
const versionList = ref<{ label: string; value: string }[]>([]);
const selectedDevice = ref();
const selectedVersion = ref();
const { isMobile } = buildResult();
const store = useOriginDataStore();

const route = useRoute();
const taskId = route.params.id;
const dataSource = ref<Record<string, number>[]>([]);
const checkErrorMsg = ref<Record<string, string>>({}); // 保存自检项目错误信息

const loading = ref(false);
const columns = computed(() => {
  if (isMobile.value) {
    return [
      {
        title: '分组',
        dataIndex: 'group',
        key: 'group',
        width: 100,
      },
      {
        title: '自检项',
        dataIndex: 'name',
        key: 'name',
        width: 100,
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
      },
    ];
  }

  let xlist = checkList;
  // 只显示需要自检的项
  if (store.data?.funcList?.length) {
    xlist = checkList.filter((x) => !x.check || x.check(store.data as Record<string, any>));
  }

  return xlist.map((item) => ({
    title: item.group,
    children: item.items.map((x) => ({
      title: x.name,
      dataIndex: x.eventName,
      key: x.eventName,
      width: 100,
    })),
  }));
});

watch(
  () => store.data,
  () => {
    if (store.data?.reqSource == 'intranet') {
      fetchData();
    }
  },
);

async function fetchData() {
  const promises = [getDeviceList({ taskId }), getVersionList({ taskId })];
  const [res1, res2] = await Promise.all(promises).catch(() => []);
  deviceList.value = (res1?.data || []).map((x: string) => ({ label: x, value: x }));
  versionList.value = (res2?.data || []).map((x: string) => ({ label: x, value: x }));

  // if (deviceList.value.length) {
  //   selectedDevice.value = deviceList.value[0]?.value;
  // }

  if (versionList.value.length) {
    selectedVersion.value = versionList.value[0]?.value;
  }

  fetchSelfTestList();
}

async function fetchSelfTestList() {
  const checkObj: Record<string, number> = toCheckObj(); // 保存自检项目状态
  const checkParams: Record<string, string[]> = toCheckParams(); // 保存自检项目参数

  checkErrorMsg.value = {};
  loading.value = true;
  const res = await getSelfTestList({
    taskId,
    deviceId: selectedDevice.value,
    version: selectedVersion.value,
  }).catch(() => null);
  loading.value = false;
  if (!res?.data?.length) {
    return;
  }

  // 需要合并所有相同事件名称的事件参数后 在判断是否参数完整
  res.data.forEach((x: Record<string, any>) => {
    // 如果是检测项 在重新赋值
    if (has(checkObj, x.event_code)) {
      let eParams = {};
      try {
        if (x.event_params) {
          eParams = JSON.parse(x.event_params);
        }
      } catch (e) {
        console.log('parse json failed');
      }

      if (!checkParams[x.event_code]) {
        checkObj[x.event_code] = 2;
      } else {
        const raw = (checkObj[x.event_code] || []) as string[];
        checkObj[x.event_code] = uniq([...raw, ...Object.keys(eParams)]);
      }
    }
  });

  Object.keys(checkObj).forEach((x) => {
    if (Array.isArray(checkObj[x])) {
      const diff = difference(checkParams[x], checkObj[x]);
      if (diff.length) {
        checkObj[x] = 1; // 有参数 但是参数为空
        checkErrorMsg.value[x] = diff.join(',') + ' 参数缺失';
      } else {
        checkObj[x] = 2;
      }
    }
  });

  if (isMobile.value) {
    // 只显示需要自检的项
    let clist = checkList;
    if (store.data?.funcList?.length) {
      clist = checkList.filter((x) => !x.check || x.check(store.data as Record<string, any>));
    }
    const xlist: Record<string, any>[] = [];
    clist.forEach((item) => {
      item.items.forEach((x) => {
        xlist.push({
          group: item.group,
          name: x.name,
          status: checkObj[x.eventName],
          eventName: x.eventName,
        });
      });
    });
    dataSource.value = xlist;
  } else {
    dataSource.value = [checkObj];
  }
}
</script>

<style scoped lang="less">
.self-test-box {
  width: 100%;

  .inner-title {
    color: rgba(0, 0, 0, 0.85);
    font-family: AlibabaPuHuiTi2.0-85Bold;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .selectbox {
    &.mobilebox {
      flex-direction: column;
      width: 100%;
      gap: 10px;
      .boxitem {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
      }
    }
    .boxitem {
      width: 300px;

      .boxlabel {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }
  }
}
</style>
