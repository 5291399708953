<script setup lang="ts">
import { useOriginDataStore } from '@/stores/originDataStore';
import { debounce } from 'lodash';
import { buildResult } from '../hooks/useResult';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';

const { isMobile } = buildResult();
const store = useOriginDataStore();
const originData = computed(() => store.data);
const router = useRouter();

const goUrl = (url: string) => {
  window.open(url, '_blank');
};
const debouncedGoUrl = debounce(goUrl, 300);

const showCocosEncrypt = computed(() => {
  const platform = originData.value?.platform;
  const engineVersion = originData.value?.engineVersion;
  return (platform === 1 || platform === 2) && engineVersion?.toLowerCase()?.startsWith('cocos');
});

function goToEncrypt() {
  if (!originData.value?.pkg) {
    message.error('缺少包名参数');
    return;
  }
  router.push({
    path: '/cocos/encrypt',
    query: {
      pkg: originData.value?.pkg,
      businessId: originData.value?.businessId,
    },
  });
}
</script>
<template>
  <div class="detail-info__bottom">
    <div v-show="originData?.platform != 3">
      <a-button type="primary" class="dl-btn" @click="debouncedGoUrl(originData?.ipaUrl)" v-if="!isMobile">
        <img src="@/assets/downloadicon.png" alt="" class="icon" />
        {{ originData?.platform === 2 ? '下载ipa' : '下载apk' }}
      </a-button>
      <a-button type="primary" class="dl-btn" @click="debouncedGoUrl(originData?.installUrl)" v-else>
        <img src="@/assets/downloadicon.png" alt="" class="icon" />
        {{ originData?.platform === 2 ? '下载ipa' : '下载apk' }}
      </a-button>
    </div>
    <a-button type="primary" class="dl-btn" @click="debouncedGoUrl(originData?.projectUrl)">
      <img src="@/assets/dld_pkg.png" alt="" class="icon" />
      {{ originData?.dpChannel === 'XYX_UNITY_DOMESTIC' ? '下载配置' : '下载工程' }}
    </a-button>
    <a-button type="primary" class="dl-btn" @click="debouncedGoUrl(originData?.docUrl)">
      <img src="@/assets/wiki.png" alt="" class="icon" />
      对接文档
    </a-button>
    <a-button type="primary" class="dl-btn" @click="debouncedGoUrl(originData?.logFileUrl)" v-show="!isMobile && originData?.logFileUrl">
      <img src="@/assets/pkg_log.png" alt="" class="icon" />
      打包日志
    </a-button>
    <a-button type="primary" class="dl-btn" @click="goToEncrypt" v-show="showCocosEncrypt">COCOS加密</a-button>
  </div>
</template>
<style scoped lang="less">
.detail-info__bottom {
  margin-top: 11px;
  display: flex;
  justify-content: flex-start;
  .dl-btn {
    width: 110px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  .icon {
    margin-right: 3px;
  }
}
</style>

<style scoped lang="less">
@media (max-width: 550px) {
  .mobile-viewport .detail-info__bottom {
    margin-left: calc(50vw - 180px);
  }
}
</style>
